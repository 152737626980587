import React, { useEffect, useState } from 'react';

export default function MNIST() {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        fetch('/MachineLearning/index.html')
            .then(response => response.text())
            .then(html => setHtmlContent(html));
    }, []);

    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}
