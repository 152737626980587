import React, { useState } from 'react';
import react_logo from './images/react-logo.png';
import nodeJS_logo from './images/nodejs-logo.png';
import JavaScript_logo from './images/JavaScript-logo.png';
import HTML_logo from './images/HTML5-logo.png';
import CSS_logo from './images/CSS3-logo.png';
import CPP_logo from './images/cpp-logo.png';
import Java_logo from './images/Java-Logo.png';
import GitHub_logo from './images/GitHub-logo.png';
import Google_Cloud_logo from './images/google-cloud-logo.png';
import MongoDB_logo from './images/mongoDB-logo.png';
import Auth0_logo from './images/auth0-logo.png';


export default function TickerFeed() {


    const hardCodedData = [
        { title: 'React', image: react_logo },
        { title: 'NodeJS', image: nodeJS_logo },
        { title: 'JavaScript', image: JavaScript_logo },
        { title: 'HTML', image: HTML_logo },
        { title: 'CSS', image: CSS_logo },
        { title: 'C++', image: CPP_logo },
        { title: 'Java', image: Java_logo },
        { title: 'GitHub', image: GitHub_logo },
        { title: 'Google Cloud', image: Google_Cloud_logo },
        { title: 'MongoDB', image: MongoDB_logo },
        { title: 'Auth0', image: Auth0_logo },
    ];

    return (
        <div className="ticker">
            <div className="ticker__items">
                {hardCodedData.map((items, index) => (
                    <img className='ticker-image' key={`first-set-${index}`} src={items.image} alt={items.title} />
                ))}
            </div>
            <div className="ticker__items">
                {hardCodedData.map((items, index) => (
                    <img className='ticker-image' key={`first-set-${index}`} src={items.image} alt={items.title} />
                ))}
            </div>
            <div className="ticker__items">
                {hardCodedData.map((items, index) => (
                    <img className='ticker-image' key={`first-set-${index}`} src={items.image} alt={items.title} />
                ))}
            </div>
        </div>
    );
};




