import NavBar from "./NavBar";
import Footer from "./Footer";
import MealMapLogo from "./images/logo512.png";
import MNISTCover from "./images/MNISTCover.png";

export default function ProjectPage() {
    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <NavBar />
            <h1 className="top">My Projects</h1>
            <a className="project-card" href="https://mealmaps.us">
                <div className="project-thumbnail">
                    <img src={MealMapLogo} alt='mealmap.us'></img>
                    <h2>Meal Maps</h2>
                </div>
                <div className="project-description">
                    <h3>A full stack web application that makes planning and preparing food much easier</h3>
                    <h4>*See blog post for details*</h4>
                </div>

            </a>
            <a className="project-card" href="https://github.com/efrink-dev/MNISTSorting">
                <div className="project-thumbnail">
                    <img src={MNISTCover} alt='NeuralNet'></img>
                    <h2>Handwritten Number Recognizer</h2>
                </div>
                <div className="project-description">
                    <h3>A demonstration of a Neural Network that can sort handwritten numbers</h3>
                    <h4>Numbers displayed are separate from training data</h4>
                </div>

            </a>

            <Footer />
        </div>
    )
}