import NavBar from "./NavBar";
import Footer from "./Footer";

export default function SchoolPage() {
    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <NavBar />
            <h1 className="top">I am currently a Junior at Barrett Honors College at Arizona State University</h1>
            <div className="school-progress-grid">
                <div className="course-container">
                    <h2>Completed Courses</h2>
                    <ul>
                        <li>Logic in Computer Science</li>
                        <li>Principles of Programming</li>
                        <li>Object Oriented Programing and Data</li>
                        <li>Computer Organization and Assembly Language</li>
                        <li>Introduction to Programming Languages</li>
                        <li>Data Structures and Algorithms</li>
                        <li>Digital Design Fundamentals</li>
                        <li>Computing Ethics</li>
                        <li>Theoretical Computer Science</li>
                        <li>Software Engineering</li>
                        <li>Information Assurance (Cybersecurity)</li>
                    </ul>
                </div>
                <div className="course-container">
                    <h2>In Progress Courses</h2>
                    <ul>
                        <li>Computer Networks</li>
                        <li>Operating Systems</li>
                        <li>Linear Algebra</li>

                    </ul>
                </div>
                <div className="more-information-container">
                    <h2>More Information</h2>
                    <h3>GPA: 3.3</h3>
                    <h3>Graduating May 2025</h3>
                    <h4>Member of Software Developers Association (SODA)</h4>
                    <h4>Member of ASU Outdoors Club</h4>
                </div>
            </div>
            <h2 className="note">** None of my publically listed projects are related to or affiliated with any ASU course material **</h2>
            <Footer />
        </div>
    )
}