import NavBar from "./NavBar";
import canyonPic from "./images/IMG_7914.jpg";
import TickerFeed from "./TickerFeed";
import emailLogo from "./images/email-logo.png";
import linkedinLogo from "./images/linkedin-logo.png";
import Footer from "./Footer";
export default function HomePage() {
    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <NavBar />
            <div className="blurb-grid">
                <div className="blurb-img">
                    <img className='canyon-pic' src={canyonPic} alt="Ethan Frink" />
                </div>
                <div className="blurb-content">
                    <h1>Ethan Frink</h1>
                    <h2>Software Development</h2>
                    <br />
                    <h3>A passionate detail oriented developer that loves to create, explore, and solve problems using world changing Technologies</h3>
                    <br />
                    <h3>Contact me:  </h3><a href="mailto: efrink29@gmail.com"><img className="contact-logo" src={emailLogo} alt="efrink29@gmail.com"></img></a>
                    <a href="https://www.linkedin.com/in/ethan-frink-asu/"><img className="contact-logo" src={linkedinLogo} alt="Linkedin"></img></a>

                </div>
            </div>
            <h1 className='section-header'>Skills / Technologies</h1>
            <TickerFeed />

            <div className="tech-list">
                <h2>I have valuable experience in a variety of languages and technologies. Here's what I am currently using:</h2>
                <div className="tech-list-item">
                    <h3>React.js: </h3>
                    <p>I believe that UI is the most important part of any App. React has made creating elegant, reliable, and user-friendly interfaces much easier in my apps.</p>
                </div>
                <div className="tech-list-item">
                    <h3>Node.js: </h3>
                    <p>Node has been the backbone of most of my projects ever since I discovered it. Its versatility and widespread support makes it the obvious choice for any new project.</p>
                </div>
                <div className="tech-list-item">
                    <h3>MongoDB: </h3>
                    <p>MongoDB has quickly become one of my favorite database technologies because of its ease of use, speed, and reliability.</p>
                </div>
                <div className="tech-list-item">
                    <h3>Google Cloud Services: </h3>
                    <p>Google cloud was the first cloud provider I decided to use and its cloud storage, compute engine, and hosting have given me tons of flexibility</p>
                </div>
            </div>
            <Footer />
        </div>
    )
}