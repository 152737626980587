import NavBar from "./NavBar";
import Footer from "./Footer";
import mealmapimg from "./images/logo512.png";
import React, { useState } from 'react';

export default function BlogPage() {
    const blogPosts = [
        {
            title: "Meal Maps Version 0.1.0 Release",
            image: mealmapimg,
            date: "June 10, 2023",
            content: "Meal Maps 0.1.0  is the first production release for meal maps. \
                      The goal of this release is to get new user signups and to test / moniter app infrastructure.\
                      &\
                      The features included in this release are: &\
                      **Browse** - Allows users to search the web for recipes using the spoonacular api. (currently only supports text querys and filter by type and allergy exclusions)&\
                      **Map** - Allows users to create and load maps that are stored on a serverless database. The ingredients list for each recipe is parsed and sorted by aisle.&.\
                      &Next steps for meal maps:&\
                      **Fix any major issues** - Users can report issues directly to me as they arise&\
                      **Add custom recipe support** - The next major update for meal maps will include the ability to save recipes (outside of plans) and add new ones (only accessable to the user that created it)&\
                      **Add more search filters** - More filters are ready to be implemented very soon"
        },
        // Other blog posts...
    ];

    const [selectedPost, setSelectedPost] = useState(null);

    const handleThumbnailClick = (post) => {
        setSelectedPost(post);
    };

    return (
        <div style={{ position: 'relative', minHeight: '100vh' }}>
            <NavBar />

            {selectedPost === null ?
                <div>
                    <h1 className="top">Blog Posts</h1>
                    <div className="blog-posts-grid"><div></div>{
                        blogPosts.map((post, index) => (
                            <div className="blog-post-thumbnail" key={index} onClick={() => handleThumbnailClick(post)}>
                                <img src={post.image} alt={post.title} />
                                <h3>{post.title}</h3>
                            </div>
                        ))
                    }<div></div></div></div>
                :
                <div>
                    <h1 className="top"></h1>
                    <div className="full-post">
                        <div className="post-head">
                            <h1>{selectedPost.title}</h1>
                            <img src={selectedPost.image} alt={selectedPost.title} />
                            <h4>{`Posted : ${selectedPost.date}`}</h4>
                        </div>

                        {selectedPost.content.split("&").map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                        <button className="close-post-button" onClick={() => setSelectedPost(null)}>Close Post</button>
                    </div></div>
            }
            <Footer />
        </div>
    );
}

