import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./components/HomePage";
import ProjectPage from "./components/Projects";
import WorkPage from "./components/Work";
import SchoolPage from "./components/School";
import './App.css'
import BlogPage from "./components/BlogPage";
import MNIST from "./components/MNIST";

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Navigate to="/home" />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/work" element={<WorkPage />} />
        <Route path="/school" element={<SchoolPage />} />
        <Route path="/MachineLearning" element={<MNIST />} />

      </Routes>
    </Router>
  );
}

